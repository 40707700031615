


















import { mdiHelpCircle } from "@mdi/js";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class HelpIcon extends Vue {
  /** Tooltip text */
  @Prop({ default: "Game Help" })
  tooltip!: string;

  icons = { mdiHelpCircle };

  /** Help icon click handler */
  @Emit("click")
  showHelp() {
    // Do nothing
  }
}
