



























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class FloodItHelpDialog extends Vue {
  /** Whether the dialog is shown */
  @Prop({ required: true })
  value!: boolean;

  /**
   * Close handler
   */
  @Emit("input")
  @Emit("close")
  onClose(): boolean {
    return false;
  }
}
