











import { Component, Vue } from "vue-property-decorator";

// Components
import { HelpIcon } from "@components";
import { FloodIt as FloodItGame, GamePage } from "@components/Games";
import FloodItHelpDialog from "@components/Games/FloodIt/FloodItHelpDialog.vue";

// Utilities
import games from "@/games";

@Component({
  components: {
    FloodItGame,
    FloodItHelpDialog,
    GamePage,
    HelpIcon,
  },
})
export default class FloodIt extends Vue {
  game = games.floodIt;
  isHelpDialogShown = false;
}
